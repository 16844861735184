<template>
    <div class='mainColumn' v-if="loaded">

        <SuccessesPeriodSelector v-bind:successes-data="successesData" class='periodSelector'
            ref="successesPeriodSelectorRef" />


        <SuccessesFeed v-bind:successes="successes" ref="feedComponent" class="feedView"
            @loadMoreSuccessesEmit="loadMoreSuccesses()" @appViewportChanged="appViewportChanged()"
            @removeSuccess="removeSuccess" />


        <AddSuccessModal v-show="showAddRemoveSuccess" v-bind:addRemoveSuccessModalProps="addRemoveSuccessModalProps"
            @hideAddSuccessModal="rejectAddingSuccessClick" @successAdded="successAdded" ref="addSuccessModal"
            @removeSuccessYes="removeSuccessYes" @removeSuccessNo="removeSuccessNo"
            @addSuccessModalMounted="addSuccessModalMounted" />

        <BackButtonSuccessesPage v-bind:successes-data="successesData" class='backButton' />

        <button class="addButton" @click="addSuccessClick">
            <span class="addSign">+</span>
        </button>
    </div>
</template>

<script>
import axios from "axios";
import SuccessesPeriodSelector from './SuccessesPeriodSelector.vue';
import BackButtonSuccessesPage from './BackButtonSuccessesPage.vue';
import SuccessesFeed from "./SuccessesFeed.vue";
import AddSuccessModal from "./AddSuccessModal.vue";
import router from "@/main";

export default {
    name: 'SuccessesView',
    components: {
        SuccessesPeriodSelector,
        BackButtonSuccessesPage,
        SuccessesFeed,
        AddSuccessModal,
    },

    data() {
        return {
            successesData: null,

            successes: [],

            page: 0,
            limit: 10,
            totalPages: 0,

            addSuccessClass: "addSuccessHidden",
            removeSuccessClass: "removeSuccessVisible",

            successToRemove: 9,

            removeSuccessVisible: false,

            showAddRemoveSuccess: false,

            addRemoveSuccessModalProps: {
                showAddSuccess: false,
                showRemoveSuccess: false,
                successData: this.successData,
            },

            successIdToRemove: null,

            loaded: false,
        };
    },

    async mounted() {
        this.loaded = false;

        await this.loadSuccesses();

        window.Telegram.WebApp.disableVerticalSwipes();

        this.loaded = true;
    },

    methods: {

        async loadSuccesses() {
            var initDataBase64 = btoa(window.Telegram.WebApp.initData);

            await axios
                .get(`${this.hostname}/successes/getData?initData=${initDataBase64}&periodId=${this.periodId}&periodType=${this.periodType}&page=${this.page}`)
                .then(response => {
                    if (response.status === 200) {
                        this.successesData = response.data;
                        this.successesData.periodId = this.periodId;

                        this.successes = this.successesData.successes;

                        this.addRemoveSuccessModalProps.successesData = this.successesData;
                    }
                })
                .catch(error => {
                    alert(error);

                    if (error.response && error.response.status === 401) {
                        router.push('/unathorized');
                    } else {
                        router.push('/serverErrorPage');
                    }
                });
        },

        async loadMoreSuccesses() {
            var initDataBase64 = btoa(window.Telegram.WebApp.initData);

            this.page += 1;

            await axios
                .get(`${this.hostname}/successes/getData?initData=${initDataBase64}&page=${this.page}`)
                .then(response => {
                    if (response.status === 200) {
                        this.successesData = response.data;
                        //this.successes.push(...this.successesData.successes);
                        this.successes = [...this.successes, ...this.successesData.successes];

                        this.addRemoveSuccessModalProps.successesData = this.successesData;

                        //this.totalPages = Math.ceil(response.headers['x-total-count'] / this.limit);
                        //this.successesData = response.data;
                        //this.successesData.periodId = this.periodId;
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        router.push('/unathorized');
                    } else {
                        router.push('/serverErrorPage');
                    }
                });
        },

        async successAdded() {
            var initDataBase64 = btoa(window.Telegram.WebApp.initData);

            await axios
                .get(`${this.hostname}/successes/getData?initData=${initDataBase64}&periodId=${this.periodId}&periodType=${this.periodType}&page=0`)
                .then(response => {
                    if (response.status === 200) {
                        var firstItem = response.data.successes[0];
                        //this.successes.push(firstItem);
                        this.successes = [firstItem, ...this.successes];
                    }
                })
                .catch(error => {
                    alert(error);

                    if (error.response && error.response.status === 401) {
                        router.push('/unathorized');
                    } else {
                        router.push('/serverErrorPage');
                    }
                });
        },

        addSuccessClick() {
            this.addRemoveSuccessModalProps.showAddSuccess = true;
            this.showAddRemoveSuccess = true;
            this.$refs.successesPeriodSelectorRef.hideTitle();
        },

        rejectAddingSuccessClick() {
            this.addRemoveSuccessModalProps.showAddSuccess = false;
            this.showAddRemoveSuccess = false;
            this.$refs.successesPeriodSelectorRef.showTitle();
        },

        async removeSuccess(successId) {
            this.successIdToRemove = successId;

            this.addRemoveSuccessModalProps.showRemoveSuccess = true;
            this.showAddRemoveSuccess = true;
        },

        async removeSuccessYes() {
            if (this.successIdToRemove != null) {
                var initDataBase64 = btoa(window.Telegram.WebApp.initData);

                await axios
                    .put(`${this.hostname}/successes/removeSuccess?initData=${initDataBase64}&successId=${this.successIdToRemove}`)
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            router.push("/unathorized");
                        } else {
                            router.push('/serverErrorPage')
                        }
                    });

                var item = this.successes.map(item => item.successId).indexOf(this.successIdToRemove);
                this.successes.splice(item, 1);

                this.successIdToRemove = null;

                this.addRemoveSuccessModalProps.showRemoveSuccess = false;
                this.showAddRemoveSuccess = false;
            }
        },

        removeSuccessNo() {
            this.addRemoveSuccessModalProps.showRemoveSuccess = false;
            this.showAddRemoveSuccess = false;
        },

        appViewportChanged() {
            /*
            this.$refs.feedComponent.scrollBy({
                top: -100,
                left: 0,
                behavior: "smooth",
            });
            */
        }

    },
}

</script>

<style scoped>
.mainColumn {
    /*height: calc(var(--tg-viewport-stable-height) - 30vh);*/
    /*min-height: calc(var(--tg-viewport-stable-height) - 4vh);*/
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

.periodSelector {
    display: flex;
    justify-content: flex-start;
    justify-self: flex-start;
}

.feedView {

    /*
    height: 100%;
    min-height: 100%;
    align-items: stretch;
    */
    /*
    margin-top: -80vh;
    display: flex;

    justify-content: flex-start;
    justify-self: flex-start;
    */
}

.backButton {
    position: absolute;
    margin-top: 88vh;
    /*
    flex: 1;
    margin-top: -23vh;
    */
    /*
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    */
    /*
    display: flex;
    justify-content: flex-end;
    /*
    justify-self: bottom;
    justify-items: self-end;
    */
    /*margin-top: 50vh;*/
}

.feedColumn {
    /*
    display: flex;
    flex-direction: column;
    */
}

.addButton {
    position: fixed;
    width: 10vh;
    height: 10vh;
    bottom: 12vh;
    right: 5vh;

    background-color: rgba(137, 119, 255, .75);
    color: #FFF;
    border-radius: 50px;
    border-color: transparent;
}

.addSign {
    font-family: Garamond, serif;
    font-size: 10vh;

    position: fixed;
    bottom: 12.15vh;
    right: 7.57vh;
}

/*
.addSuccessHidden {
    display: none;
    visibility: hidden;
}

.addSuccessVisible {
    display: block;
    visibility: visible;
}
*/
</style>