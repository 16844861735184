<template>
  <div class="mainColumn">
    <PeriodBackNextSelector @leftClicked="leftClicked" @rightClicked="rightClicked"
      @periodTypeChanged="periodTypeChanged" v-bind:status-results-data="statusResultsDataTriangle"
      class="periodSelector" v-if="this.resultsMode" />

    <div id="container" class="mainContainer">
    </div>

    <BackButtonResultsPage v-bind:status-results-data="statusResultsDataTriangle" class="backButton"
      v-if="this.resultsMode" />

  </div>
</template>

<script>
import { createApp } from "vue";
import { AppConfig } from "vue";
import Konva from 'konva';
import Transformer from 'konva';
import axios from "axios";
import router from "@/main";
import BackButtonResultsPage from "@/components/ui/ResultsPageUI/BackButtonResultsPage.vue";
import PeriodBackNextSelector from "@/components/ui/ResultsPageUI/PeriodBackNextSelector.vue";

export default {
  name: "VisualResultsPage",
  components: {
    BackButtonResultsPage,
    PeriodBackNextSelector,
  },
  props: {
    resultsMode: {
      type: Object,
      required: true,
      default: true,
    }
  },
  data() {
    return {
      statusResultsDataTriangle: null,
      periodType: 'WEEKLY',
      periodId: null,

      fieldColor: null,
      brightLineColor: null,
      textColor: null,
      linesTextColor: null,
      substrateColor: null,

      loaded: false,      
    };
  },

  async mounted() {
    this.loaded = false;

    await this.loadCurrentPeriodId(this.periodType);

    await this.loadData();

    this.loadColors();

    this.drawTriangle();
  },

  methods: {
    async loadCurrentPeriodId(periodType) {
      let initDataBase64 = btoa(window.Telegram.WebApp.initData);

      await axios
        .get(`${this.hostname}/results/getResultsCurrentPeriodId?initData=${initDataBase64}&periodType=${periodType}`)
        .then(response => {
          if (response.status === 200) {
            this.periodId = response.data;
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage')
          }
        });
    },

    async loadData() {
      let initDataBase64 = btoa(window.Telegram.WebApp.initData);

      await axios
        .get(`${this.hostname}/results/getData?initData=${initDataBase64}&periodId=${this.periodId}&periodType=${this.periodType}&resultsMode=${this.resultsMode}`)
        .then(response => {
          if (response.status === 200) {
            this.statusResultsDataTriangle = response.data;
            this.statusResultsDataTriangle.periodId = this.periodId;
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage');
          }
        });
    },

    async leftClicked() {
      this.periodId -= 1;

      await this.loadData();

      this.drawTriangle();
    },

    async rightClicked() {
      this.periodId += 1;

      await this.loadData();

      this.drawTriangle();
    },

    async periodTypeChanged(periodType) {
      this.periodType = periodType;

      await this.loadCurrentPeriodId(periodType);

      await this.loadData();

      this.drawTriangle();
    },

    loadColors() {
      if (window.Telegram.WebApp.colorScheme === 'dark') {
        this.fieldColor = window.getComputedStyle(document.documentElement).getPropertyValue('--field-foreground-dark');
        this.brightLineColor = window.getComputedStyle(document.documentElement).getPropertyValue('--field-bright-line-dark');
        this.linesTextColor = window.getComputedStyle(document.documentElement).getPropertyValue('--lines-text-foreground-dark');
        this.textColor = window.getComputedStyle(document.documentElement).getPropertyValue('--text-foreground-dark');
        this.substrateColor = window.getComputedStyle(document.documentElement).getPropertyValue('--field-substrate-line-dark');
      } else {
        this.fieldColor = window.getComputedStyle(document.documentElement).getPropertyValue('--field-foreground-light');
        this.brightLineColor = window.getComputedStyle(document.documentElement).getPropertyValue('--field-bright-line-light');
        this.linesTextColor = window.getComputedStyle(document.documentElement).getPropertyValue('--lines-text-foreground-light');
        this.textColor = window.getComputedStyle(document.documentElement).getPropertyValue('--text-foreground-light');
        this.substrateColor = window.getComputedStyle(document.documentElement).getPropertyValue('--field-substrate-line-light');
      }
    },

    drawTriangle() {
      let periodDurationDays = this.statusResultsDataTriangle.periodDurationDays;

      let width = null; window.innerWidth - (0.0379 * window.innerWidth);
      let height = null; (window.innerWidth - (0.0379 * window.innerWidth)) + (0.1263 * window.innerWidth);

      if (this.resultsMode) {
        width = window.innerWidth - (0.0379 * window.innerWidth);
        height = (window.innerWidth - (0.0379 * window.innerWidth)) + (0.1263 * window.innerWidth);
      } else {
        width = window.innerWidth - (0.175 * window.innerWidth);
        height = (window.innerWidth - (0.175 * window.innerWidth));
      }

      var stage = new Konva.Stage({
        container: 'container',
        width: width,
        height: this.resultsMode ? height : height + 0.1 * window.innerWidth,
      });

      var layer = new Konva.Layer();

      stage.add(layer);

      //Линии-подложки:

      let moneyTiredLine = new Konva.Line({
        points: [(0.189 * width), (0.8556 * width), (0.5 * width), (0.3037 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let moneyGoodLine = new Konva.Line({
        points: [(0.1462 * width), (0.8260 * width), (0.4572 * width), (0.2741 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let moneyGreatLine = new Konva.Line({
        points: [(0.1034 * width), (0.7964 * width), (0.4144 * width), (0.2445 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let peoplesTiredLine = new Konva.Line({
        points: [(0.5 * width), (0.3037 * width), (0.8110 * width), (0.8556 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let peoplesGoodLine = new Konva.Line({
        points: [(0.5428 * width), (0.2741 * width), (0.8538 * width), (0.8260 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let peoplesGreatLine = new Konva.Line({
        points: [(0.5856 * width), (0.2445 * width), (0.8966 * width), (0.7964 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let IGreatLine = new Konva.Line({
        points: [(0.1890 * width), (0.9606 * width), (0.8110 * width), (0.9606 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let IGoodLine = new Konva.Line({
        points: [(0.1890 * width), (0.9081 * width), (0.8110 * width), (0.9081 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let ITiredLine = new Konva.Line({
        points: [(0.1890 * width), (0.8556 * width), (0.8110 * width), (0.8556 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Тонкие серые линии к иконкам статусов:

      let leftGoodThinLine1 = new Konva.Line({
        points: [(0.1890 * width), (0.9081 * width), (0.1050 * width), (0.9081 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      var leftGoodThinLine2 = new Konva.Line({
        points: [(0.1462 * width), (0.8260 * width), (0.1050 * width), (0.9081 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let leftGreatThinLine1 = new Konva.Line({
        points: [(0.0210 * width), (0.9606 * width), (0.1034 * width), (0.7963 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let leftGreatThinLine2 = new Konva.Line({
        points: [(0.1890 * width), (0.9606 * width), (0.0210 * width), (0.9606 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let rightGoodThinLine1 = new Konva.Line({
        points: [(0.8538 * width), (0.8260 * width), (0.8950 * width), (0.9081 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let rightGoodThinLine2 = new Konva.Line({
        points: [(0.8110 * width), (0.9081 * width), (0.8950 * width), (0.9081 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let rightGreatThinLine1 = new Konva.Line({
        points: [(0.8966 * width), (0.7963 * width), (0.9790 * width), (0.9606 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let rightGreatThinLine2 = new Konva.Line({
        points: [(0.8110 * width), (0.9606 * width), (0.9790 * width), (0.9606 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let topGoodThinLine1 = new Konva.Line({
        points: [(0.4572 * width), (0.2742 * width), (0.4974 * width), (0.2010 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let topGoodThinLine2 = new Konva.Line({
        points: [(0.5428 * width), (0.2742 * width), (0.4974 * width), (0.2010 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let topGreatThinLine1 = new Konva.Line({
        points: [(0.4144 * width), (0.2445 * width), (0.5 * width), (0.0876 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      let topGreatThinLine2 = new Konva.Line({
        points: [(0.5856 * width), (0.2445 * width), (0.5 * width), (0.0876 * width)],
        stroke: this.substrateColor,
        strokeWidth: (0.0053 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Лейблы направлений:

      let moneyCaption = new Konva.Text({
        text: this.statusResultsDataTriangle.moneyCaption,
        fontSize: Math.floor(9.0 * width / 100.0),
        fontFamily: 'Sonatina',
        fill: this.fieldColor,
        rotation: -61
      });

      let moneyCaptionSize = moneyCaption.measureSize(this.statusResultsDataTriangle.moneyCaption);

      let moneyCaptionSizeWidth = moneyCaptionSize.width;

      let moneyLineLength = Math.sqrt(Math.pow(((0.4144 * width) - (0.1034 * width)), 2) +
        Math.pow(((0.1979 * width) - (0.7963 * width)), 2));

      let moneyCaptionDeltaWidth = (moneyLineLength - moneyCaptionSizeWidth) / 2.0;

      moneyCaption.setPosition({
        x: (0.1034 * width) - 1.53 * 0.0394 * width * Math.cos(-62.5),
        y: (0.7964 * width) - 1.35 * moneyCaptionDeltaWidth * Math.cos(-62.5)
      });

      let peoplesCaption = new Konva.Text({
        text: this.statusResultsDataTriangle.peoplesCaption,
        fontSize: Math.floor(9.0 * width / 100.0),
        fontFamily: 'Sonatina',
        fill: this.fieldColor,
        rotation: 61.0
      });

      let peoplesCaptionSize = peoplesCaption.measureSize(this.statusResultsDataTriangle.peoplesCaption);

      let peoplesCaptionSizeWidth = peoplesCaptionSize.width;

      let peoplesLineLength = moneyLineLength;

      let peoplesCaptionDeltaWidth = (peoplesLineLength - peoplesCaptionSizeWidth) / 2.0;

      peoplesCaption.setPosition({
        x: (0.5856 * width) + 4.6 * 0.0394 * width * Math.cos(62.5),
        y: (0.2445 * width) + 0.25 * peoplesCaptionDeltaWidth * Math.cos(62.5)
      })

      let ICaption = new Konva.Text({
        text: this.statusResultsDataTriangle.iamCaption,
        fontSize: Math.floor(9.0 * width / 100.0),
        fontFamily: 'Sonatina',
        fill: this.fieldColor,
        rotation: 0
      });

      let ICaptionSize = moneyCaption.measureSize(this.statusResultsDataTriangle.iamCaption);

      let ICaptionSizeWidth = ICaptionSize.width;

      let ICaptionSizeHeight = ICaptionSize.height;

      let ICaptionDeltaWidth = ICaptionSizeWidth / 2.0;

      if (this.resultsMode) {
        ICaption.setPosition({
          x: 0.5 * width - ICaptionDeltaWidth,
          y: height - 1.3 * ICaptionSizeHeight
        });
      } else {
        ICaption.setPosition({
          x: 0.5 * width - ICaptionDeltaWidth,
          y: height - 0.001 * ICaptionSizeHeight
        });
      }

      //Яркий отрезок Я и Деньги "Отлично":

      let moneyGreatLineSubstrateLength = Math.sqrt(Math.pow(((0.4144 * width) - (0.1034 * width)), 2) +
        Math.pow(((0.2445 * width) - (0.7964 * width)), 2));

      let moneyGreatDays = parseFloat(this.statusResultsDataTriangle?.moneyGreatResult);

      let moneyGreatLineFirstSegmentLength = (((periodDurationDays - moneyGreatDays) / 2.0) / periodDurationDays)
        * moneyGreatLineSubstrateLength;

      let vectorMoneyGreatCX = (0.4144 * width) - (0.1034 * width);
      let vectorMoneyGreatCY = (0.2445 * width) - (0.7964 * width);

      let vectorMoneyGreatCModul = Math.sqrt(Math.pow(vectorMoneyGreatCX, 2) + Math.pow(vectorMoneyGreatCY, 2));

      let moneyGreatDX = vectorMoneyGreatCX / vectorMoneyGreatCModul;
      let moneyGreatDY = vectorMoneyGreatCY / vectorMoneyGreatCModul;

      let moneyGreatDeltaX = moneyGreatDX * moneyGreatLineFirstSegmentLength;
      let moneyGreatDeltaY = moneyGreatDY * moneyGreatLineFirstSegmentLength;

      let moneyGreatLineSegment = new Konva.Line({
        points: [(0.1034 * width) + moneyGreatDeltaX,
        (0.7964 * width) + moneyGreatDeltaY,
        (0.4144 * width) - moneyGreatDeltaX,
        (0.2445 * width) - moneyGreatDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я и Деньги "Отлично":

      let moneyGreatDaysCaptionText = this.statusResultsDataTriangle?.moneyGreatResult + ' ' +
        this.statusResultsDataTriangle?.moneyGreatResultCaption;

      let moneyGreatDaysCaption = new Konva.Text({
        text: moneyGreatDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: -61
      });

      let moneyGreatDaysCaptionWidth = moneyGreatDaysCaption.measureSize(moneyGreatDaysCaptionText).width;

      let moneyGreatLineCenterWidth = (0.5) * moneyGreatLineSubstrateLength - (0.5) * moneyGreatDaysCaptionWidth;

      let moneyGreatCenterDeltaX = moneyGreatDX * moneyGreatLineCenterWidth;
      let moneyGreatCenterDeltaY = moneyGreatDY * moneyGreatLineCenterWidth;

      moneyGreatDaysCaption.setPosition({
        x: (0.1034 * width) + moneyGreatCenterDeltaX - 0.4 * 0.0394 * width,
        y: (0.7963 * width) + moneyGreatCenterDeltaY - 0.25 * 0.0394 * width
      });

      //Яркий отрезок Я и Деньги "Хорошо":

      let moneyGoodLineSubstrateLength = Math.sqrt(Math.pow(((0.4572 * width) - (0.1462 * width)), 2) +
        Math.pow(((0.2741 * width) - (0.8260 * width)), 2));

      let moneyGoodDays = parseFloat(this.statusResultsDataTriangle?.moneyGoodResult);

      let moneyGoodLineFirstSegmentLength = (((periodDurationDays - moneyGoodDays) / 2.0) / periodDurationDays)
        * moneyGoodLineSubstrateLength;

      let vectorMoneyGoodCX = (0.4572 * width) - (0.1462 * width);
      let vectorMoneyGoodCY = (0.2741 * width) - (0.8260 * width);

      let vectorMoneyGoodCModul = Math.sqrt(Math.pow(vectorMoneyGoodCX, 2) + Math.pow(vectorMoneyGoodCY, 2));

      let moneyGoodDX = vectorMoneyGoodCX / vectorMoneyGoodCModul;
      let moneyGoodDY = vectorMoneyGoodCY / vectorMoneyGoodCModul;

      let moneyGoodDeltaX = moneyGoodDX * moneyGoodLineFirstSegmentLength;
      let moneyGoodDeltaY = moneyGoodDY * moneyGoodLineFirstSegmentLength;

      let moneyGoodLineSegment = new Konva.Line({
        points: [(0.1462 * width) + moneyGoodDeltaX,
        (0.8260 * width) + moneyGoodDeltaY,
        (0.4572 * width) - moneyGoodDeltaX,
        (0.2741 * width) - moneyGoodDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width), //10px
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я и Деньги "Хорошо":

      let moneyGoodDaysCaptionText = this.statusResultsDataTriangle?.moneyGoodResult + ' ' +
        this.statusResultsDataTriangle?.moneyGoodResultCaption;

      let moneyGoodDaysCaption = new Konva.Text({
        text: moneyGoodDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: -61
      });

      let moneyGoodDaysCaptionWidth = moneyGreatDaysCaption.measureSize(moneyGoodDaysCaptionText).width;

      let moneyGoodLineCenterWidth = (0.5) * moneyGoodLineSubstrateLength - (0.5) * moneyGoodDaysCaptionWidth;

      let moneyGoodCenterDeltaX = moneyGoodDX * moneyGoodLineCenterWidth;
      let moneyGoodCenterDeltaY = moneyGoodDY * moneyGoodLineCenterWidth;

      moneyGoodDaysCaption.setPosition({
        x: (0.1462 * width) + moneyGoodCenterDeltaX - 0.4 * 0.0394 * width,
        y: (0.8260 * width) + moneyGoodCenterDeltaY - 0.25 * 0.0394 * width
      });

      //Яркий отрезок Я и Деньги "Устал (-а)":

      let moneyTiredLineSubstrateLength = Math.sqrt(Math.pow(((0.5 * width) - (0.189 * width)), 2) +
        Math.pow(((0.3037 * width) - (0.8556 * width)), 2));

      let moneyTiredDays = parseFloat(this.statusResultsDataTriangle?.moneyTiredResult);

      let moneyTiredLineFirstSegmentLength = (((periodDurationDays - moneyTiredDays) / 2.0) / periodDurationDays)
        * moneyTiredLineSubstrateLength;

      let vectorMoneyTiredCX = (0.5 * width) - (0.189 * width);
      let vectorMoneyTiredCY = (0.3037 * width) - (0.8556 * width);

      let vectorMoneyTiredCModul = Math.sqrt(Math.pow(vectorMoneyTiredCX, 2) + Math.pow(vectorMoneyTiredCY, 2));

      let moneyTiredDX = vectorMoneyTiredCX / vectorMoneyTiredCModul;
      let moneyTiredDY = vectorMoneyTiredCY / vectorMoneyTiredCModul;

      let moneyTiredDeltaX = moneyTiredDX * moneyTiredLineFirstSegmentLength;
      let moneyTiredDeltaY = moneyTiredDY * moneyTiredLineFirstSegmentLength;

      let moneyTiredLineSegment = new Konva.Line({
        points: [(0.189 * width) + moneyTiredDeltaX,
        (0.8556 * width) + moneyTiredDeltaY,
        (0.5 * width) - moneyTiredDeltaX,
        (0.3037 * width) - moneyTiredDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width), //10px
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я и Деньги "Устал (-а)":

      let moneyTiredDaysCaptionText = this.statusResultsDataTriangle?.moneyTiredResult + ' ' +
        this.statusResultsDataTriangle?.moneyTiredResultCaption;

      let moneyTiredDaysCaption = new Konva.Text({
        text: moneyTiredDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: -61
      });

      let moneyTiredDaysCaptionWidth = moneyTiredDaysCaption.measureSize(moneyTiredDaysCaptionText).width;

      let moneyTiredLineCenterWidth = (0.5) * moneyTiredLineSubstrateLength - (0.5) * moneyTiredDaysCaptionWidth;

      let moneyTiredCenterDeltaX = moneyTiredDX * moneyTiredLineCenterWidth;
      let moneyTiredCenterDeltaY = moneyTiredDY * moneyTiredLineCenterWidth;

      moneyTiredDaysCaption.setPosition({
        x: (0.189 * width) + moneyTiredCenterDeltaX - 0.4 * 0.0394 * width,
        y: (0.8556 * width) + moneyTiredCenterDeltaY - 0.25 * 0.0394 * width
      });

      //Яркий отрезок Я и Люди "Отлично":

      let peoplesGreatLineSubstrateLength = Math.sqrt(Math.pow(((0.8966 * width) - (0.5856 * width)), 2) +
        Math.pow(((0.7964 * width) - (0.2445 * width)), 2));

      let peoplesGreatDays = parseFloat(this.statusResultsDataTriangle?.peoplesGreatResult);

      let peoplesGreatLineFirstSegmentLength = (((periodDurationDays - peoplesGreatDays) / 2.0) / periodDurationDays)
        * peoplesGreatLineSubstrateLength;

      let vectorPeoplesGreatCX = (0.8966 * width) - (0.5856 * width);
      let vectorPeoplesGreatCY = (0.7964 * width) - (0.2445 * width);

      let vectorPeoplesGreatCModul = Math.sqrt(Math.pow(vectorPeoplesGreatCX, 2) + Math.pow(vectorPeoplesGreatCY, 2));

      let peoplesGreatDX = vectorPeoplesGreatCX / vectorPeoplesGreatCModul;
      let peoplesGreatDY = vectorPeoplesGreatCY / vectorPeoplesGreatCModul;

      let peoplesGreatDeltaX = peoplesGreatDX * peoplesGreatLineFirstSegmentLength;
      let peoplesGreatDeltaY = peoplesGreatDY * peoplesGreatLineFirstSegmentLength;

      let peoplesGreatLineSegment = new Konva.Line({
        points: [(0.5856 * width) + peoplesGreatDeltaX,
        (0.2445 * width) + peoplesGreatDeltaY,
        (0.8966 * width) - peoplesGreatDeltaX,
        (0.7964 * width) - peoplesGreatDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width), //10px
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я и Люди "Отлично":

      let peoplesGreatDaysCaptionText = this.statusResultsDataTriangle?.peoplesGreatResult + ' ' +
        this.statusResultsDataTriangle?.peoplesGreatResultCaption;

      let peoplesGreatDaysCaption = new Konva.Text({
        text: peoplesGreatDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: 61
      });

      let peoplesGreatDaysCaptionWidth = peoplesGreatDaysCaption.measureSize(peoplesGreatDaysCaptionText).width;

      let peoplesGreatLineCenterWidth = (0.5) * peoplesGreatLineSubstrateLength - (0.5) * peoplesGreatDaysCaptionWidth;

      let peoplesGreatCenterDeltaX = peoplesGreatDX * peoplesGreatLineCenterWidth;
      let peoplesGreatCenterDeltaY = peoplesGreatDY * peoplesGreatLineCenterWidth;

      peoplesGreatDaysCaption.setPosition({
        x: (0.5856 * width) + peoplesGreatCenterDeltaX + 0.5 * 0.0394 * width,
        y: (0.2445 * width) + peoplesGreatCenterDeltaY - 0.12 * 0.0394 * width
      });

      //Яркий отрезок Я и Люди "Хорошо":

      let peoplesGoodLineSubstrateLength = Math.sqrt(Math.pow(((0.8538 * width) - (0.5428 * width)), 2) +
        Math.pow(((0.8260 * width) - (0.2741 * width)), 2));

      let peoplesGoodDays = parseFloat(this.statusResultsDataTriangle?.peoplesGoodResult);

      let peoplesGoodLineFirstSegmentLength = (((periodDurationDays - peoplesGoodDays) / 2.0) / periodDurationDays)
        * peoplesGoodLineSubstrateLength;

      let vectorPeoplesGoodCX = (0.8538 * width) - (0.5428 * width);
      let vectorPeoplesGoodCY = (0.8260 * width) - (0.2741 * width);

      let vectorPeoplesGoodCModul = Math.sqrt(Math.pow(vectorPeoplesGoodCX, 2) + Math.pow(vectorPeoplesGoodCY, 2));

      let peoplesGoodDX = vectorPeoplesGoodCX / vectorPeoplesGoodCModul;
      let peoplesGoodDY = vectorPeoplesGoodCY / vectorPeoplesGoodCModul;

      let peoplesGoodDeltaX = peoplesGoodDX * peoplesGoodLineFirstSegmentLength;
      let peoplesGoodDeltaY = peoplesGoodDY * peoplesGoodLineFirstSegmentLength;

      let peoplesGoodLineSegment = new Konva.Line({
        points: [(0.5428 * width) + peoplesGoodDeltaX,
        (0.2741 * width) + peoplesGoodDeltaY,
        (0.8538 * width) - peoplesGoodDeltaX,
        (0.8260 * width) - peoplesGoodDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width), //10px
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я и Люди "Хорошо":

      let peoplesGoodDaysCaptionText = this.statusResultsDataTriangle?.peoplesGoodResult + ' ' +
        this.statusResultsDataTriangle?.peoplesGoodResultCaption;

      let peoplesGoodDaysCaption = new Konva.Text({
        text: peoplesGoodDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: 61
      });

      let peoplesGoodDaysCaptionWidth = peoplesGoodDaysCaption.measureSize(peoplesGoodDaysCaptionText).width;

      let peoplesGoodLineCenterWidth = (0.5) * peoplesGoodLineSubstrateLength - (0.5) * peoplesGoodDaysCaptionWidth;

      let peoplesGoodCenterDeltaX = peoplesGoodDX * peoplesGoodLineCenterWidth;
      let peoplesGoodCenterDeltaY = peoplesGoodDY * peoplesGoodLineCenterWidth;

      peoplesGoodDaysCaption.setPosition({
        x: (0.5428 * width) + peoplesGoodCenterDeltaX + 0.5 * 0.0394 * width,
        y: (0.2741 * width) + peoplesGoodCenterDeltaY - 0.12 * 0.0394 * width
      });

      //Яркий отрезок Я и Люди "Устал (-а)":

      let peoplesTiredLineSubstrateLength = Math.sqrt(Math.pow(((0.8110 * width) - (0.5 * width)), 2) +
        Math.pow(((0.8556 * width) - (0.3037 * width)), 2));

      let peoplesTiredDays = parseFloat(this.statusResultsDataTriangle?.peoplesTiredResult);

      let peoplesTiredLineFirstSegmentLength = (((periodDurationDays - peoplesTiredDays) / 2.0) / periodDurationDays)
        * peoplesTiredLineSubstrateLength;

      let vectorPeoplesTiredCX = (0.8110 * width) - (0.5 * width);
      let vectorPeoplesTiredCY = (0.8556 * width) - (0.3037 * width);

      let vectorPeoplesTiredCModul = Math.sqrt(Math.pow(vectorPeoplesTiredCX, 2) + Math.pow(vectorPeoplesTiredCY, 2));

      let peoplesTiredDX = vectorPeoplesTiredCX / vectorPeoplesTiredCModul;
      let peoplesTiredDY = vectorPeoplesTiredCY / vectorPeoplesTiredCModul;

      let peoplesTiredDeltaX = peoplesTiredDX * peoplesTiredLineFirstSegmentLength;
      let peoplesTiredDeltaY = peoplesTiredDY * peoplesTiredLineFirstSegmentLength;

      let peoplesTiredLineSegment = new Konva.Line({
        points: [(0.5 * width) + peoplesTiredDeltaX,
        (0.3037 * width) + peoplesTiredDeltaY,
        (0.8110 * width) - peoplesTiredDeltaX,
        (0.8556 * width) - peoplesTiredDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я и Люди "Устал (-а)":

      let peoplesTiredDaysCaptionText = this.statusResultsDataTriangle?.peoplesTiredResult + ' ' +
        this.statusResultsDataTriangle?.peoplesTiredResultCaption;

      let peoplesTiredDaysCaption = new Konva.Text({
        text: peoplesTiredDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: 61
      });

      let peoplesTiredDaysCaptionWidth = peoplesTiredDaysCaption.measureSize(peoplesTiredDaysCaptionText).width;

      let peoplesTiredLineCenterWidth = (0.5) * peoplesTiredLineSubstrateLength - (0.5) * peoplesTiredDaysCaptionWidth;

      let peoplesTiredCenterDeltaX = peoplesTiredDX * peoplesTiredLineCenterWidth;
      let peoplesTiredCenterDeltaY = peoplesTiredDY * peoplesTiredLineCenterWidth;

      peoplesTiredDaysCaption.setPosition({
        x: (0.5 * width) + peoplesTiredCenterDeltaX + 0.5 * 0.0394 * width,
        y: (0.3037 * width) + peoplesTiredCenterDeltaY - 0.12 * 0.0394 * width
      });

      //Яркий отрезок Я "Отлично":

      let IGreatLineSubstrateLength = Math.sqrt(Math.pow(((0.8110 * width) - (0.1890 * width)), 2) +
        Math.pow(((0.9606 * width) - (0.9606 * width)), 2));

      let iamGreatDays = parseFloat(this.statusResultsDataTriangle?.iamGreatResult);

      let IGreatLineFirstSegmentLength = (((periodDurationDays - iamGreatDays) / 2.0) / periodDurationDays)
        * IGreatLineSubstrateLength;

      let vectorIGreatCX = (0.8110 * width) - (0.1890 * width);
      let vectorIGreatCY = (0.9606 * width) - (0.9606 * width);

      let vectorIGreatCModul = Math.sqrt(Math.pow(vectorIGreatCX, 2) + Math.pow(vectorIGreatCY, 2));

      let IGreatDX = vectorIGreatCX / vectorIGreatCModul;
      let IGreatDY = vectorIGreatCY / vectorIGreatCModul;

      let IGreatDeltaX = IGreatDX * IGreatLineFirstSegmentLength;
      let IGreatDeltaY = IGreatDY * IGreatLineFirstSegmentLength;

      let IGreatLineSegment = new Konva.Line({
        points: [(0.1890 * width) + IGreatDeltaX,
        (0.9606 * width) + IGreatDeltaY,
        (0.8110 * width) - IGreatDeltaX,
        (0.9606 * width) - IGreatDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width), //10px
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я "Отлично":

      let IGreatDaysCaptionText = this.statusResultsDataTriangle?.iamGreatResult + ' ' +
        this.statusResultsDataTriangle?.iamGreatResultCaption;

      let IGreatDaysCaption = new Konva.Text({
        text: IGreatDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: 0
      });

      let IGreatDaysCaptionWidth = IGreatDaysCaption.measureSize(IGreatDaysCaptionText).width;

      let IGreatLineCenterWidth = (0.5) * IGreatLineSubstrateLength - (0.5) * IGreatDaysCaptionWidth;

      let IGreatCenterDeltaX = IGreatDX * IGreatLineCenterWidth;
      let IGreatCenterDeltaY = IGreatDY * IGreatLineCenterWidth;

      IGreatDaysCaption.setPosition({
        x: (0.1890 * width) + IGreatCenterDeltaX,
        y: (0.9606 * width) + IGreatCenterDeltaY - 0.0205 * width
      });

      //Яркий отрезок Я "Хорошо":

      let IGoodLineSubstrateLength = Math.sqrt(Math.pow(((0.8110 * width) - (0.1890 * width)), 2) +
        Math.pow(((0.9081 * width) - (0.9081 * width)), 2));

      let iamGoodDays = parseFloat(this.statusResultsDataTriangle?.iamGoodResult);

      let IGoodLineFirstSegmentLength = (((periodDurationDays - iamGoodDays) / 2.0) / periodDurationDays)
        * IGoodLineSubstrateLength;

      let vectorIGoodCX = (0.8110 * width) - (0.1890 * width);
      let vectorIGoodCY = (0.9081 * width) - (0.9081 * width);

      let vectorIGoodCModul = Math.sqrt(Math.pow(vectorIGoodCX, 2) + Math.pow(vectorIGoodCY, 2));

      let IGoodDX = vectorIGoodCX / vectorIGoodCModul;
      let IGoodDY = vectorIGoodCY / vectorIGoodCModul;

      let IGoodDeltaX = IGoodDX * IGoodLineFirstSegmentLength;
      let IGoodDeltaY = IGoodDY * IGoodLineFirstSegmentLength;

      let IGoodLineSegment = new Konva.Line({
        points: [(0.1890 * width) + IGoodDeltaX,
        (0.9081 * width) + IGoodDeltaY,
        (0.8110 * width) - IGoodDeltaX,
        (0.9081 * width) - IGoodDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width), //10px
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я "Хорошо":

      let IGoodDaysCaptionText = this.statusResultsDataTriangle?.iamGoodResult + ' ' +
        this.statusResultsDataTriangle?.iamGoodResultCaption;

      let IGoodDaysCaption = new Konva.Text({
        text: IGoodDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: 0
      });

      let IGoodDaysCaptionWidth = IGoodDaysCaption.measureSize(IGoodDaysCaptionText).width;

      let IGoodLineCenterWidth = (0.5) * IGoodLineSubstrateLength - (0.5) * IGoodDaysCaptionWidth;

      let IGoodCenterDeltaX = IGoodDX * IGoodLineCenterWidth;
      let IGoodCenterDeltaY = IGoodDY * IGoodLineCenterWidth;

      IGoodDaysCaption.setPosition({
        x: (0.1890 * width) + IGoodCenterDeltaX,
        y: (0.9081 * width) + IGoodCenterDeltaY - 0.0205 * width
      });

      //Яркий отрезок Я "Устал (-а)":

      let ITiredLineSubstrateLength = Math.sqrt(Math.pow(((0.8110 * width) - (0.1890 * width)), 2) +
        Math.pow(((0.8556 * width) - (0.8556 * width)), 2));

      let iamTiredDays = parseFloat(this.statusResultsDataTriangle?.iamTiredResult);

      let ITiredLineFirstSegmentLength = (((periodDurationDays - iamTiredDays) / 2.0) / periodDurationDays)
        * ITiredLineSubstrateLength;

      let vectorITiredCX = (0.8110 * width) - (0.1890 * width);
      let vectorITiredCY = (0.8556 * width) - (0.8556 * width);

      let vectorITiredCModul = Math.sqrt(Math.pow(vectorITiredCX, 2) + Math.pow(vectorITiredCY, 2));

      let ITiredDX = vectorITiredCX / vectorITiredCModul;
      let ITiredDY = vectorITiredCY / vectorITiredCModul;

      let ITiredDeltaX = ITiredDX * ITiredLineFirstSegmentLength;
      let ITiredDeltaY = ITiredDY * ITiredLineFirstSegmentLength;

      let ITiredLineSegment = new Konva.Line({
        points: [(0.1890 * width) + ITiredDeltaX,
        (0.8556 * width) + ITiredDeltaY,
        (0.8110 * width) - ITiredDeltaX,
        (0.8556 * width) - ITiredDeltaY],
        stroke: this.brightLineColor,
        strokeWidth: (0.0394 * width),
        lineCap: 'round',
        lineJoin: 'round',
        tension: 1,
      });

      //Количество дней Я "Устал (-а)":

      let ITiredDaysCaptionText = this.statusResultsDataTriangle?.iamTiredResult + ' ' +
        this.statusResultsDataTriangle?.iamTiredResultCaption;

      let ITiredDaysCaption = new Konva.Text({
        text: ITiredDaysCaptionText,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.linesTextColor,
        rotation: 0
      });

      let ITiredDaysCaptionWidth = ITiredDaysCaption.measureSize(ITiredDaysCaptionText).width;

      let ITiredLineCenterWidth = (0.5) * ITiredLineSubstrateLength - (0.5) * ITiredDaysCaptionWidth;

      let ITiredCenterDeltaX = ITiredDX * ITiredLineCenterWidth;
      let ITiredCenterDeltaY = ITiredDY * ITiredLineCenterWidth;

      ITiredDaysCaption.setPosition({
        x: (0.1890 * width) + ITiredCenterDeltaX,
        y: (0.8556 * width) + ITiredCenterDeltaY - 0.0205 * width
      });

      //Центральная статистика:

      let greatDaysStat = new Konva.Text({
        text: this.statusResultsDataTriangle?.greatDaysStatCaption + ' ' + this.statusResultsDataTriangle?.greatDaysStatResult,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.textColor,
        rotation: 0
      });

      greatDaysStat.setPosition({
        x: 0.5 * width - 0.142 * width,
        y: 0.6716 * width
      });

      let restDaysStat = new Konva.Text({
        text: this.statusResultsDataTriangle?.restDaysStatCaption + ' ' + this.statusResultsDataTriangle?.moneyRestResult + ',' + this.statusResultsDataTriangle?.peoplesRestResult + ',' + this.statusResultsDataTriangle?.iamRestResult,
        fontSize: Math.floor(3.8 * width / 100.0),
        fontFamily: 'Garamond',
        fontStyle: 'bold',
        fill: this.textColor,
        rotation: 0
      });

      restDaysStat.setPosition({
        x: 0.5 * width - 0.125 * width,
        y: 0.6716 * width + 0.05 * width
      });

      //Добавление графики в слой:

      layer.add(moneyGreatLine);
      layer.add(moneyGoodLine);
      layer.add(moneyTiredLine);

      layer.add(peoplesGreatLine);
      layer.add(peoplesGoodLine);
      layer.add(peoplesTiredLine);

      layer.add(IGreatLine);
      layer.add(IGoodLine);
      layer.add(ITiredLine);


      layer.add(leftGoodThinLine1);
      layer.add(leftGoodThinLine2);
      layer.add(leftGreatThinLine1);
      layer.add(leftGreatThinLine2);

      layer.add(rightGoodThinLine1);
      layer.add(rightGoodThinLine2);
      layer.add(rightGreatThinLine1);
      layer.add(rightGreatThinLine2);

      layer.add(topGoodThinLine1);
      layer.add(topGoodThinLine2);
      layer.add(topGreatThinLine1);
      layer.add(topGreatThinLine2);

      layer.add(moneyCaption);
      layer.add(peoplesCaption);
      layer.add(ICaption);

      if (this.statusResultsDataTriangle?.moneyGreatResult > 0) {
        layer.add(moneyGreatLineSegment);
      }

      if (this.statusResultsDataTriangle?.moneyGoodResult > 0) {
        layer.add(moneyGoodLineSegment);
      }

      if (this.statusResultsDataTriangle?.moneyTiredResult > 0) {
        layer.add(moneyTiredLineSegment);
      }

      layer.add(moneyGreatDaysCaption);
      layer.add(moneyGoodDaysCaption);
      layer.add(moneyTiredDaysCaption);

      if (this.statusResultsDataTriangle?.peoplesGreatResult > 0) {
        layer.add(peoplesGreatLineSegment);
      }

      if (this.statusResultsDataTriangle?.peoplesGoodResult > 0) {
        layer.add(peoplesGoodLineSegment);
      }

      if (this.statusResultsDataTriangle?.peoplesTiredResult > 0) {
        layer.add(peoplesTiredLineSegment);
      }

      layer.add(peoplesGreatDaysCaption);
      layer.add(peoplesGoodDaysCaption);
      layer.add(peoplesTiredDaysCaption);

      if (this.statusResultsDataTriangle?.iamGreatResult > 0) {
        layer.add(IGreatLineSegment);
      }

      if (this.statusResultsDataTriangle?.iamGoodResult > 0) {
        layer.add(IGoodLineSegment);
      }

      if (this.statusResultsDataTriangle?.iamTiredResult > 0) {
        layer.add(ITiredLineSegment);
      }

      layer.add(IGreatDaysCaption);
      layer.add(IGoodDaysCaption);
      layer.add(ITiredDaysCaption);

      layer.add(greatDaysStat);
      layer.add(restDaysStat);

      //Иконки слева:

      Konva.Image.fromURL('/img/tired.a7d991d4.png', function (leftTiredImage) {
        leftTiredImage.setSize({ width: (0.0577 * width), height: (0.0577 * width) });
        leftTiredImage.setPosition({ x: (0.1675 * width), y: (0.8247 * width) });
        layer.add(leftTiredImage);
        layer.draw();
      });

      Konva.Image.fromURL('/img/good.963247af.png', function (leftGoodImage) {
        leftGoodImage.setSize({ width: (0.0630 * width), height: (0.0630 * width) });
        leftGoodImage.setPosition({ x: (0.0840 * width), y: (0.8766 * width) });
        layer.add(leftGoodImage);
        layer.draw();
      });

      Konva.Image.fromURL('/img/great.410654e1.png', function (leftGreatImage) {
        leftGreatImage.setSize({ width: (0.0577 * width), height: (0.0577 * width) });
        leftGreatImage.setPosition({ x: (0.0026 * width), y: (0.9318 * width) });
        layer.add(leftGreatImage);
        layer.draw();
      });

      //Иконки справа:

      Konva.Image.fromURL('/img/tired.a7d991d4.png', function (rightTiredImage) {
        rightTiredImage.setSize({ width: (0.0577 * width), height: (0.0577 * width) });
        rightTiredImage.setPosition({ x: (0.7774 * width), y: (0.8220 * width) });
        layer.add(rightTiredImage);
        layer.draw();
      });

      Konva.Image.fromURL('/img/good.963247af.png', function (rightGoodImage) {
        rightGoodImage.setSize({ width: (0.0630 * width), height: (0.0630 * width) });
        rightGoodImage.setPosition({ x: (0.8635 * width), y: (0.8766 * width) });
        layer.add(rightGoodImage);
        layer.draw();
      });

      Konva.Image.fromURL('/img/great.410654e1.png', function (rightGreatImage) {
        rightGreatImage.setSize({ width: (0.0577 * width), height: (0.0577 * width) });
        rightGreatImage.setPosition({ x: (0.9370 * width), y: (0.9318 * width) });
        layer.add(rightGreatImage);
        layer.draw();
      });

      //Иконки сверху:

      Konva.Image.fromURL('/img/tired.a7d991d4.png', function (topTiredImage) {
        topTiredImage.setSize({ width: (0.0577 * width), height: (0.0577 * width) });
        topTiredImage.setPosition({ x: (0.4711 * width), y: (0.2828 * width) });
        layer.add(topTiredImage);
        layer.draw();
      });

      Konva.Image.fromURL('/img/good.963247af.png', function (topGoodImage) {
        topGoodImage.setSize({ width: (0.0630 * width), height: (0.0630 * width) });
        topGoodImage.setPosition({ x: (0.4659 * width), y: (0.1747 * width) });
        layer.add(topGoodImage);
        layer.draw();
      });

      Konva.Image.fromURL('/img/great.410654e1.png', function (topGreatImage) {
        topGreatImage.setSize({ width: (0.0577 * width), height: (0.0577 * width) });
        topGreatImage.setPosition({ x: (0.4711 * width), y: (0.0723 * width) });
        layer.add(topGreatImage);
        layer.draw();
      });

      layer.draw();
    },

  }

}
</script>

<style scoped>
.mainColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainCanvas {
  background: antiquewhite;
}

.periodSelector {
  display: flex;
  justify-content: flex-start;
  justify-self: flex-start;
}

.mainContainer {
  display: flex;
  justify-content: center;
  justify-self: center;
}

.backButton {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
}
</style>