<template v-cloak>
  <div class="mainColumn" v-if="loaded">

    <div class="topElement">
      <div class="titleFlex">
        <div :class="titleCenterElementClass">
          {{ statusOfTheDayData?.title }}
        </div>
      </div>

    </div>

    <square-status-of-the-day v-bind:status-of-the-day-data="statusOfTheDayData" class="mainSquare" />

    <results-button-status-of-the-day v-bind:status-of-the-day-data="statusOfTheDayData" class="resultsButton" />

  </div>
</template>

<script>
import SquareStatusOfTheDay from "@/components/ui/StatusOfTheDayUI/SquareStatusOfTheDay";
import ResultsButtonStatusOfTheDay from "@/components/ui/StatusOfTheDayUI/ResultsButtonStatusOfTheDay.vue";
import axios from "axios";
import router from "@/main";

export default {
  name: "StatusOfTheDay",
  components: { ResultsButtonStatusOfTheDay, SquareStatusOfTheDay },

  data() {
    return {
      statusOfTheDayData: null,
      titleCenterElementClass: null,

      loaded: false,
    }
  },

  async mounted() {
    this.loaded = false;

    var initDataBase64 = btoa(window.Telegram.WebApp.initData);

    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.titleCenterElementClass = "titleCenterElementDark";
    } else {
      this.titleCenterElementClass = "titleCenterElementLight";
    }

    var today = new Date();
    var timezoneOffset = today.getTimezoneOffset();

    await axios
      .put(`${this.hostname}/userBase/saveUserTimezone?initData=${initDataBase64}&timezone=${timezoneOffset}`)
      .catch(error => {
        if (error.response && error.response.status === 401) {
          router.push("/unathorized");
        } else {
          router.push('/serverErrorPage')
        }
      });

    await axios
      .get(`${this.hostname}/serverErrorCaption/getData?initData=${initDataBase64}`)
      .then(response => {
        if (response.status === 200) {
          this.app.config.globalProperties.serverErrorCaption = response.data;
        }
      })
      .catch(error => {
        alert(error);
        router.push('/serverErrorPage')
      });

    await axios
      .get(`${this.hostname}/statusOfTheDay/selectField?initData=${initDataBase64}`)
      .then(response => {
        (this.statusOfTheDayData = response.data);

        this.loaded = true;
      })
      .catch(error => {
        alert(error);
        if (error.response && error.response.status === 401) {
          router.push("/unathorized");
        } else {
          router.push('/serverErrorPage')
        }
      });
  }
}
</script>

<style scoped>
.mainColumn {
  height: calc(var(--tg-viewport-stable-height) - 4vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topElement {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.titleFlex {
  justify-content: flex-end;
  /*height: 5vh;*/
  align-self: center;
}

.titleCenterElementDark {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  color: var(--text-foreground-dark);
  /*color: #BABABA;*/
  text-align: center;
}

.titleCenterElementLight {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  /*color: dimgray;*/
  color: var(--text-foreground-light);
  text-align: center;
  filter: brightness(0.75);
}

.mainSquare {
  display: flex;
  justify-content: center;
  justify-self: center;
}

.resultsButton {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
}

.loader {
  margin: auto;

  width: 15vw;
  height: 15vw;
}

.loadingBar {
  height: 1.5vh;
  background-color: green;

  animation: loadingBarAnimation 3s infinite;

  border-radius: 9vh;
}

@keyframes loadingBarAnimation {
  0% {
    width: 0%;
  }

  25% {
    width: 50%;
  }

  50% {
    width: 75%;
  }

  75% {
    width: 85%;
  }

  100% {
    width: 100%;
  }
}

[v-cloak] > * { display: none;}
[v-cloak]::before { content: "Loading..."; }
</style>