<template>
    <div class="successEditor" v-if="this.addRemoveSuccessModalProps.showAddSuccess">

        <textarea @input="updateSuccessValue($event.target.value)" 
            style="width:100vw; height: 40vh; background-color: white;">
        </textarea>

        <div class="buttonsHolder">
            <button class="saveButton" @click="saveSuccess">
                <span class="saveCaption">{{ addRemoveSuccessModalProps?.successesData?.saveCaption }}</span>
            </button>
            <button class="rejectButton" @click="rejectChanges">
                <span class="rejectButton">{{ addRemoveSuccessModalProps?.successesData?.rejectCaption }}</span>
            </button>
        </div>
    </div>

    <div class="confirmationHolder" v-if="this.addRemoveSuccessModalProps.showRemoveSuccess">
        <div class="confirmationBox">
            <span>{{ addRemoveSuccessModalProps?.successesData?.successRemoveConfirmation }}</span>

            <div class="buttonsHolder">
                <button class="yesButton" @click="removeYesClick" >{{ addRemoveSuccessModalProps?.successesData?.yesCaption }}</button>
                <button class="noButton" @click="removeNoClick">{{ addRemoveSuccessModalProps?.successesData?.noCaption }}</button>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from '@/main';

export default {
    name: "AddSuccessModal",
    props: {
        addRemoveSuccessModalProps: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            quillEditor: null,
            successValue: null,
        }
    },

    mounted() {
        /*
        var container = document.getElementById("successEditor");

        var options = {
            modules: {
                toolbar: true,
            },
            theme: 'snow',
        };

        this.quillEditor = new Quill(container, options);
        */
    },

    methods: {

        updateSuccessValue(successValue) {
            this.successValue = successValue;
        },

        async saveSuccess() {
            var initDataBase64 = btoa(window.Telegram.WebApp.initData);

            await axios
                .post(`${this.hostname}/successes/saveSuccess?initData=${initDataBase64}&success=${this.successValue}`)
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        router.push("/unathorized");
                    } else {
                        router.push('/serverErrorPage')
                    }
                });

            this.$emit('successAdded');
            this.$emit("hideAddSuccessModal");
        },

        rejectChanges() {
            this.showAddSuccess = false;
            this.$emit("hideAddSuccessModal");
        },

        removeYesClick() {
            this.$emit('removeSuccessYes');
        },

        removeNoClick() {
            this.$emit('removeSuccessNo');
        },

        selectEmoji() {

        }
    },
}
</script>

<style scoped>
.successEditor {
    position: fixed;

    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    z-index: 999;

    /*background-color: rgba(0,0,0,0.35);*/
}

.successEditorQuill {
    position: fixed;

    width: 40vw;
    height: 30vh;

    left: 20vw;
    top: 50vh;
}

.buttonsHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.saveButton {
    margin-top: -3vh;
    width: 30vw;
    height: 5vh;
}

.rejectButton {
    margin-top: -3vh;
    width: 30vw;
    height: 5vh;
}

.confirmationHolder {
    font-family: Garamond, serif;
    font-size: 3.5vh;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}

.confirmationBox {
    display: flex;
    flex-direction: column;

    position: fixed;

    width: 50vw;
    height: 20vh;

    top: 40vh;
    left: 25vw;

    border-radius: 1vh;
    /*border: 2px solid black;*/

    background-color: white;
}

.buttonsHolder {
    display: flex;
    flex-direction: row;

    margin-top: 3vh;
}

.yesButton {
    width: 15vw;
    height: 6vh;
}

.noButton {
    width: 15vw;
    height: 6vh;
}
</style>