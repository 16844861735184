<template>
  <div class='buttonHolder'>
    <div class="resultsButtonHolder">
      <button :class='resultsButtonClass' role='button' @click='resultsClickHandler'>
        <span class='buttonLabel'>{{ statusOfTheDayData?.resultsButtonLabel }} ></span>
      </button>
    </div>
    <div class="successesButtonHolder">
      <button :class='successesButtonClass' role='button' @click='successesClickHandler'>
        <span class='buttonLabel'>{{ statusOfTheDayData?.successesButtonCaption }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import router from '@/main';

export default {
  name: 'ResultsButtonStatusOfTheDay',
  props: ['statusOfTheDayData'],

  data() {
    return {
      resultsButtonClass: null,
      successessButtonClass: null,
      statusResultsData: null,
    }
  },

  mounted() {
    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.resultsButtonClass = 'resultsButtonDark';
      this.successesButtonClass = 'successesButtonDark';
    } else {
      this.resultsButtonClass = 'resultsButtonLight';
      this.successesButtonClass = 'successesButtonLight';
    }
  },

  methods: {
    resultsClickHandler() {
      //router.push(`/visualResultsPage/${this.statusOfTheDayData?.resultsCurrentPeriodId}`);
      //router.push({ name: 'VisualResultsPage', params: { periodData: JSON.stringify(response.data)}});

      router.push('/visualResultsPage/');
    },

    successesClickHandler() {
      router.push('/successesPage');
    },
  }
}
</script>

<style scoped>
.buttonHolder {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  /*align-self: center;*/
}

.buttonLabel {
  font-size: 5vw;
}

.resultsButtonDark {
  /*position: absolute;*/
  width: 45vw;
  height: 10vh;
  /*background-color: #c2fbd7;*/
  /*background-color: #42B883;*/
  background-color: var(--button-background-dark);
  border-radius: 100px;
  /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
  /*color: green;*/
  color: var(--button-label-dark);
  /*color: #BABABA;*/
  /*color: #42B883;*/
  cursor: pointer;
  display: inline-block;
  font-family: Garamond;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  /*
    margin-left: -45vw;
    margin-top: -10.85vh;
     */
}

.successesButtonDark {
  /*position: absolute;*/
  width: 45vw;
  height: 10vh;
  /*background-color: #c2fbd7;*/
  /*background-color: #42B883;*/
  background-color: var(--button-background-dark);
  border-radius: 100px;
  /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
  /*color: green;*/
  color: var(--button-label-dark);
  /*color: #BABABA;*/
  /*color: #42B883;*/
  cursor: pointer;
  display: inline-block;
  font-family: Garamond;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  /*
    margin-left: -45vw;
    margin-top: -10.85vh;
     */
}

.resultsButtonLight {
  /*  position: absolute;*/
  width: 45vw;
  height: 10vh;
  background-color: var(--button-background-light);
  border-radius: 100px;
  /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
  /*color: green;*/
  color: var(--text-foreground-light);
  /*color: #BABABA;*/
  /*color: #42B883;*/
  cursor: pointer;
  display: inline-block;
  font-family: Garamond;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  
  /*
    margin-left: -45vw;
    margin-top: -10.85vh;
     */
}

.successesButtonLight {
  /*  position: absolute;*/
  width: 45vw;
  height: 10vh;
  /*background-color: #c2fbd7;*/
  background-color: var(--button-background-light);
  border-radius: 100px;
  /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
  color: var(--text-foreground-light);
  /*color: #BABABA;*/
  /*color: #42B883;*/
  cursor: pointer;
  display: inline-block;
  font-family: Garamond;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  /*margin-left: +10vw;*/
  /*
    margin-left: -45vw;
    margin-top: -10.85vh;
     */
}

.resultsButtonHolder {
  display: flex;
  /*margin-left: -10vw;*/
}

.successesButtonHolder {
  display: flex;
  margin-left: 5vw;
  /*margin-left: -10vw;*/
}

/*
  .button-33:hover {
    box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
    transform: scale(1.05);
  }
   */
</style>